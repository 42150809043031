.heroSection {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/heroimage-min.png);
    position: relative;
    object-fit: cover;

    & .overlay {
        height: 100%;
        width: 100%;
        background-color: #00000099;
        position: absolute;
        z-index: 0;
    }

    & .heroBox {
        height: 70vh;
        display: flex;
        align-items: center;
        max-width: 1228px;
        margin: 0 auto;
        z-index: 9;
        position: relative;

        @media (max-width:1340px) {
            flex-direction: column;
            align-items: normal;
        }
    }

    & .heroContainer {

        @media (max-width:1340px) {
            margin: 100px auto;
            width: 100%;
            padding: 0 0 0 46px;
        }

        @media (max-width:460px) {
            padding: 0px 0 0 34px;
        }
    }
}

.tabsBox {
    display: flex;
    gap: 30px;

    & .activeTab {
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 4px solid #F57158;
    }

    & .inActive {
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        font-weight: 400;
        padding-bottom: 10px;
        border-bottom: 4px solid transparent;
    }
}

.tabContent {
    & .tabContainer {
        width: 90%;

        & h2 {
            padding: 0;
            color: #fff;
            font-size: 36px;
            font-weight: 700;
            line-height: 50px;
            margin: 10px 0 24px 0;

            @media (max-width:460px) {
                font-size: 29px;
                line-height: 40px;
            }
        }

        & .formInput {
            display: flex;

            & input {
                height: 60px;
                font-size: 16px;
                line-height: 19px;
                border-radius: 3px;
                background: #FFFFFF;
                padding: 22px 18px 20px 18px;
                margin-right: 1rem;
            }

            & button {
                width: 200px;
                height: 60px;
                color: white;
                cursor: pointer;
                font-size: 16px;
                font-weight: 700;
                border-radius: 3px;
                border: 1px solid #F57158;
                background-color: #F57158;

                &:hover {
                    background-color: #fff;
                    color: #f57158;
                }
            }
        }
    }
}

.listingCard {
    & .advisorBox {
        & h4 {
            font-size: 25px;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 2rem;
            color: #212529;
        }

        & .advisorCard {
            display: grid;
            gap: 15px;
            grid-template-columns: repeat(6, 1fr);

            @media (max-width:1320px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width:768px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        & .advisorTopSection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2rem;

            & h4 {
                font-size: 25px;
                font-weight: 500;
                line-height: 30px;
                color: #212529;
                margin: 0;
            }

            & .seeAllLink {
                color: #F57158;
                font-weight: 600;
                font-size: 1rem;
                cursor: pointer;
                border: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.secondMainWrapper {
    padding: 48px;

    @media(max-width:575px) {
        padding: 42px 14px;
    }
}

.howItWork {
    padding-top: 50px;

    & .howItWorkHeading {
        text-align: center;

        & h4 {
            font-size: 25px;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 2rem;
            color: #212529;
        }
    }

    & .workTabBody {
        margin-top: 3rem;

        & .workTabsBox {
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: center;
            margin-bottom: 40px;

            & .activeTab {
                color: #363E46;
                cursor: pointer;
                font-size: 20px;
                font-weight: 700;
                padding-bottom: 10px;
                border-bottom: 4px solid #F57158;
            }

            & .inActive {
                color: #9AA0A6;
                cursor: pointer;
                font-size: 20px;
                font-weight: 400;
                padding-bottom: 10px;
                border-bottom: 4px solid transparent;
            }
        }
    }

    & .tabContentWork {
        & .wordGrid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 60px;

            @media (max-width:960px) {
                grid-template-columns: repeat(1, 1fr);
            }

            & .gridBox {
                & img {
                    border-radius: 10px;
                    width: 100%;
                }

                & h3 {
                    font-size: 22px;
                    line-height: 26px;
                    text-align: center;
                    color: #363E46;
                    margin-bottom: 0;
                    padding: 0;
                }

                & p {
                    padding: 0;
                    text-align: center;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #363E46;
                }
            }
        }
    }
}


.fetchListingsError{
    text-align: center;
    color:red;
}

.fetchListingsProgress, .noListingFound{
    text-align: center;
}

.promotionBanner{
    width: 100%;
    position: relative;
    z-index: 9;
    color: #ffff;
    background: #6DF003;
    padding: 1rem;
    & .promotionText{
        font-size: 1rem;
        font-weight: 900;
    }
    @media (min-width:460px) {
        padding: 2rem;
        & .promotionText{
            font-size: 1.5rem;
        }
    }
}